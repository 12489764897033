import * as React from 'react';

import { Template, QuestionnairePage } from 'common/layout';
import { QuestionRoute, QuestionnaireFooter } from 'modules/questionnaire';

const Question: React.FC = () => {
  return (
    <Template>
      <QuestionnairePage>
        <QuestionnairePage.Content>
          <QuestionRoute />
        </QuestionnairePage.Content>
        <QuestionnairePage.Footer>
          <QuestionnaireFooter />
        </QuestionnairePage.Footer>
      </QuestionnairePage>
    </Template>
  );
};

export default Question;
